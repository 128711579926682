import {
  signInWithPopup,
  auth,
  provider,
  db,
  doc,
  getDoc,
  setDoc,
} from "../../@configs/firebase";
import { useAuthStore } from "../../store/useAuthStore";
import { setToken, removeToken } from "./cookies";

interface UserRole {
  uid: string;
  email: string;
  name: string;
  role: "admin" | "user";
}

const AuthHandler = () => {
  const { user, setUser, logout } = useAuthStore();

  const handleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const loggedInUser = result.user;

      if (!loggedInUser) return;

      const token = await loggedInUser.getIdToken();
      setToken(token);

      const userDocRef = doc(db, "users", loggedInUser.uid);
      const userDocSnap = await getDoc(userDocRef);

      if (!userDocSnap.exists()) {
        await setDoc(userDocRef, {
          uid: loggedInUser.uid,
          name: loggedInUser.displayName,
          email: loggedInUser.email,
          role: "user",
        });

        setUser({
          uid: loggedInUser.uid,
          email: loggedInUser.email || "",
          name: loggedInUser.displayName || "",
          role: "user",
          token,
        });
      } else {
        const userData = userDocSnap.data() as UserRole;

        setUser({
          uid: userData.uid,
          email: userData.email,
          name: userData.name,
          role: userData.role,
          token,
        });
      }
    } catch (error) {
      console.error("Error logging in:", error);
    }
  };

  const handleLogout = () => {
    auth.signOut();
    removeToken();
    logout();
  };

  return { handleLogin, handleLogout };
};

export default AuthHandler;
