import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAaxm6q7SU7XPDcZvAAXpFw14Bn7nJ0YVg",
  authDomain: "auth.pctcfs.com",
  projectId: "pctcfs-f7a26",
  storageBucket: "pctcfs-f7a26.appspot.com",
  messagingSenderId: "607392504909",
  appId: "1:607392504909:web:c6dedff1388c5109f69004",
  measurementId: "G-TLFXT7LHEJ",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const db = getFirestore(app);
const analytics = getAnalytics(app);

export {
  auth,
  provider,
  signInWithPopup,
  signOut,
  db,
  doc,
  getDoc,
  setDoc,
  analytics,
};
