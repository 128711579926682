import { useState } from "react";
import { Row, Col, Drawer } from "antd";
import { withTranslation, TFunction } from "react-i18next";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";
import { Button } from "../../common/Button";

import {
  DownOutlined,
  LoginOutlined,
  LogoutOutlined,
  SettingFilled,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Dropdown, Space, Typography } from "antd";
import i18n from "i18next";

import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
  CustomButton,
} from "./styles";

import AuthHandler from "../../common/utils/AuthHandler";
import { useAuthStore } from "../../store/useAuthStore";

const Header = ({ t }: { t: TFunction }) => {
  const [visible, setVisibility] = useState(false);
  const { user } = useAuthStore();
  const { handleLogin, handleLogout } = AuthHandler();

  const toggleButton = () => {
    setVisibility(!visible);
  };

  const MenuItem = () => {
    const scrollTo = (id: string) => {
      const element = document.getElementById(id) as HTMLDivElement;
      element.scrollIntoView({
        behavior: "smooth",
      });
      setVisibility(false);
    };

    const onClick: MenuProps["onClick"] = ({ key }) => {
      switch (key) {
        case "1":
          i18n.changeLanguage("vi");
          break;
        case "2":
          i18n.changeLanguage("en");
          break;
        default:
          break;
      }
    };

    const items: MenuProps["items"] = [
      {
        key: "1",
        label: "Tiếng Việt",
        icon: (
          <SvgIcon
            src="vietnam.svg"
            aria-label="homepage"
            width="15px"
            height="15px"
          />
        ),
      },
      {
        key: "2",
        label: "English",
        icon: (
          <SvgIcon
            src="united-states.svg"
            aria-label="homepage"
            width="15px"
            height="15px"
          />
        ),
      },
    ];

    return (
      <>
        <div>
          <Dropdown
            menu={{
              items,
              onClick,
            }}
          >
            <a onClick={(e) => e.preventDefault()}>
              <CustomNavLinkSmall>
                <Span>
                  <Space>
                    <Span>
                      <SettingFilled /> {t("Language")}
                    </Span>
                    <DownOutlined />
                  </Space>
                </Span>
              </CustomNavLinkSmall>
            </a>
          </Dropdown>
          <CustomNavLinkSmall onClick={() => scrollTo("about")}>
            <Span>{t("About")}</Span>
          </CustomNavLinkSmall>
          <CustomNavLinkSmall onClick={() => scrollTo("rule")}>
            <Span>{t("Rule")}</Span>
          </CustomNavLinkSmall>
          {/* <CustomNavLinkSmall onClick={() => scrollTo("product")}>
          <Span>{t("Product")}</Span>
        </CustomNavLinkSmall> */}
          {/* <CustomNavLinkSmall onClick={() => scrollTo("footer")}>
            <Span>{t("Contact")}</Span>
          </CustomNavLinkSmall> */}
          {user && (
            <CustomNavLinkSmall style={{ width: "200px" }}>
              <CustomButton color="#fff" padding="13px 0">
                Hi, {user.name}
              </CustomButton>
            </CustomNavLinkSmall>
          )}
          {!user ? (
            <CustomNavLinkSmall style={{ width: "200px" }}>
              <Span>
                <Button onClick={handleLogin}>
                  <LoginOutlined /> {t("Login OAuth")}
                </Button>
              </Span>
            </CustomNavLinkSmall>
          ) : (
            <CustomNavLinkSmall style={{ width: "200px" }}>
              <Span>
                <Button onClick={handleLogout}>
                  <LogoutOutlined /> {t("Logout")}
                </Button>
              </Span>
            </CustomNavLinkSmall>
          )}
        </div>
      </>
    );
  };

  return (
    <HeaderSection>
      <Container>
        <Row justify="space-between">
          <LogoContainer to="/" aria-label="homepage">
            <SvgIcon src="logo.svg" width="50px" height="70px" />
          </LogoContainer>
          <NotHidden>
            <MenuItem />
          </NotHidden>
          <Burger onClick={toggleButton}>
            <Outline />
          </Burger>
        </Row>
        <Drawer closable={false} open={visible} onClose={toggleButton}>
          <Col style={{ marginBottom: "2.5rem" }}>
            <Label onClick={toggleButton}>
              <Col span={12}>
                <Menu>Menu</Menu>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem />
        </Drawer>
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
